import * as moment from "moment";

export type TimeSeriesEntry = [moment.Moment, number];

export default class TimeSeries {
  readonly entries: ReadonlyArray<TimeSeriesEntry>;

  constructor(entries: ReadonlyArray<TimeSeriesEntry>) {
    this.entries = [...entries].sort(
      ([date1], [date2]) => (date1 as any) - (date2 as any)
    );
  }

  points(): { t: moment.Moment; y: number }[] {
    return this.entries.map(([t, y]) => ({ t, y }));
  }

  values(): number[] {
    return this.entries.map(([_, value]) => value);
  }

  dates(): moment.Moment[] {
    return this.entries.map(([date, _]) => date);
  }

  interpolate(date: moment.Moment): number {
    const nextIndex = this.entries.findIndex(([entryDate]) => entryDate > date);

    const [prevDate, prevValue] = this.entries[nextIndex - 1];
    const [nextDate, nextValue] = this.entries[nextIndex];

    const currentTime = date.valueOf();
    const prevTime = prevDate.valueOf();
    const nextTime = nextDate.valueOf();

    const timeFraction = (currentTime - prevTime) / (nextTime - prevTime);
    return prevValue + (nextValue - prevValue) * timeFraction;
  }
}
