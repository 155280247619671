import React, { Component } from "react";
import * as d3 from "d3";
import { hexbin } from "d3-hexbin";

const margin = {
  top: 12,
  right: 0,
  bottom: 0,
  left: 12
};

interface Refs {
  mountPoint?: HTMLDivElement;
}

interface Props {
  rows: number;
  columns: number;
  data: Map<string, number>;
}

class Chart {
  readonly palette: Map<string, string>;
  readonly cells: [string, number][];

  constructor(
    readonly totalCells: number,
    readonly entries: Map<string, number>
  ) {
    const values = Array.from(entries.entries());
    const totalNum = values.reduce((total, [_, num]) => total + num, 0);

    const colors = [
      "#4caf50",
      "#2196f3",
      "#f44336",
      "#ffc107",
      "#8bc34a",
      "#3f51b5",
      "#e91e63",
      "#ff9800",
      "#cddc39",
      "#00bcd4",
      "#ffeb3b",
      "#ff5722",
      "#009688",
      "#03a9f4",
      "#9c27b0"
    ];
    const keys = Array.from(entries.keys());
    this.palette = new Map(
      keys.map((key, i) => [key, colors[i % colors.length]!])
    );

    this.cells = values.map<[string, number]>(([name, num], i) => [
      name,
      Math.round((num * totalCells) / totalNum)
    ]);

    let counter = 0;
    this.cells.forEach(cell => {
      const prevVal = cell[1];
      cell[1] += counter;
      counter += prevVal;
    });
  }

  get(i: number) {
    for (let [name, count] of this.cells) {
      if (i < count) {
        return name;
      }
    }
    return "";
  }
}

export default class HexagonChart extends Component<
  Props,
  { active?: string }
> {
  ref = React.createRef<HTMLDivElement>();
  chart?: Chart;

  constructor(props: Props) {
    super(props);
    this.chart = new Chart(
      this.props.columns * this.props.rows,
      this.props.data
    );
  }

  componentDidMount() {
    const width = this.ref.current!.getBoundingClientRect().width;

    const hexRadius = Math.min(
      width / ((this.props.columns + 0.5) * Math.sqrt(3)),
      12
    );

    //width = this.props.columns * hexRadius * Math.sqrt(3);
    const height = this.props.rows * 1.5 * hexRadius + 0.5 * hexRadius;

    const hb = hexbin().radius(hexRadius);

    //Calculate the center positions of each hexagon
    const points: [number, number][] = [];
    for (let i = 0; i < this.props.rows; i++) {
      for (let j = 0; j < this.props.columns; j++) {
        points.push([hexRadius * j * 1.75, hexRadius * i * 1.5]);
      }
    }

    const svg = d3
      .select(this.ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .on("mouseout", () => this.setState({ active: undefined }));

    svg
      .append("g")
      .selectAll(".hexagon")
      .data(hb(points))
      .enter()
      .append("path")
      .attr("class", "hexagon")
      .attr("d", (d: any) => `M${d.x},${d.y}${hb.hexagon()}`)
      .style(
        "fill",
        (d, i) => this.chart!.palette.get(this.chart!.get(i)) ?? "transparent"
      )
      .style("animation-delay", (_, i) => `${i * 5}ms`)
      .on("mouseover", (_, i) => this.setState({ active: this.chart?.get(i) }));
  }

  render() {
    return (
      <div>
        <div ref={this.ref} />
        <ul>
          {Array.from(this.props.data.entries()).map(([name, num]) => (
            <li
              key={name}
              className={this.state?.active === name ? "active" : ""}
            >
              <span style={{ color: this.chart?.palette.get(name) }}>
                &#x2B22;
              </span>
               {name}: {num} Gt
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
