import React, { Component } from "react";
import PopulationCount from "./PopulationCount";
import PopulationChart from "./PopulationChart";
import HumansInSpaceCount from "./HumansInSpaceCount";
import Globe from "./Globe";
import HexagonChart from "./HexagonChart";
import { store } from "./PopulationStore";

export default class LifePage extends Component<
  {},
  {
    total?: number;
    biomassMap?: Map<string, number>;
    animalsMap?: Map<string, number>;
    locationMap?: Map<string, number>;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    store.biomass.subscribe(biomass =>
      this.setState({
        total: biomass.total,
        biomassMap: new Map(Object.entries(biomass.summary)),
        animalsMap: new Map(Object.entries(biomass.animals)),
        locationMap: new Map(Object.entries(biomass.location))
      })
    );
  }

  render() {
    return (
      <div>
        <h1>Spaceprobe Earth: Life</h1>
        <p>Earth hosts {this.state.total} Gt of Carbon-based biomass.</p>
        <Globe />
        <p>
          <span style={{ color: "#4caf50" }}>⬤</span> terrestrial life makes up{" "}
          {this.state.locationMap?.get("Terrestrial")} Gt, marine-life{" "}
          {this.state.locationMap?.get("Marine")} Gt and{" "}
          {this.state.locationMap?.get("Deep Subsurface")}+ Gt deep below the
          surface.
        </p>
        <p>The present life can be split into 7 broad categories:</p>
        {this.state.biomassMap ? (
          <HexagonChart rows={11} columns={30} data={this.state.biomassMap} />
        ) : (
          <div />
        )}
        <p>
          The <span style={{ color: "#3f51b5" }}>⬢</span> 2 Gt Animals are
          divided up to:
        </p>
        {this.state.animalsMap ? (
          <HexagonChart rows={10} columns={30} data={this.state.animalsMap} />
        ) : (
          <div />
        )}
        <p>
          Although only making up 0.01 % of Earth's biomass,{" "}
          <span style={{ color: "#8bc34a" }}>⬢</span> Humans are the dominant
          species on Earth.
        </p>
        <PopulationCount />
        <PopulationChart />
        <p>
          Humans are a{" "}
          <a href="https://en.wikipedia.org/wiki/Kardashev_scale">Type I</a>{" "}
          civilization.
        </p>
        <HumansInSpaceCount />
      </div>
    );
  }
}
