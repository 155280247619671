import React, { Component } from "react";
import { store } from "./PopulationStore";
import * as d3 from "d3";
import moment from "moment";

type Point = { t: moment.Moment; y: number };

const fmt = (n: number) => (n > 0 ? `${n / 1e9} B` : "0");

export default class PopulationChart extends Component<{}, {}> {
  ref = React.createRef<HTMLDivElement>();

  async componentDidMount() {
    store.population.subscribe(population => {
      const points = population.points();
      const now = moment();
      const past = points.filter(({ t }) => t <= now);
      const future = [...past.slice(-1), ...points.filter(({ t }) => t > now)];

      const totalWidth = this.ref.current!.getBoundingClientRect().width;

      const margin = { top: 10, right: 30, bottom: 30, left: 50 },
        width = totalWidth - margin.left - margin.right,
        height = 300 - margin.top - margin.bottom;

      const svg = d3
        .select(this.ref.current)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      const x = d3
        .scaleTime()
        .domain(d3.extent(points, d => d.t.valueOf()) as [number, number])
        .range([0, width]);

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(points, d => d.y)!])
        .range([height, 0]);

      const d3line = d3
        .line<Point>()
        .x(p => x(p.t))
        .y(p => y(p.y));

      // Background grid lines.
      svg
        .append("g")
        .attr("class", "grid")
        .call(
          d3
            .axisLeft(y)
            .ticks(6)
            .tickSize(-width)
            .tickFormat(() => "")
        );

      // Solid line with past population.
      svg
        .append("path")
        .datum(past)
        .attr("fill", "none")
        .attr("stroke", "#8bc34a")
        .attr("stroke-width", 2)
        .attr("d", d3line);

      // Dashed line with future estimates.
      svg
        .append("path")
        .datum(future)
        .attr("fill", "none")
        .attr("stroke", "#8bc34a")
        .style("stroke-dasharray", "3, 3")
        .attr("stroke-width", 2)
        .attr("d", d3line);

      // X-axis.
      svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));

      // Y-axis.
      svg.append("g").call(
        d3
          .axisLeft<number>(y)
          .ticks(6)
          .tickFormat(fmt)
      );
    });
  }

  render() {
    return <div ref={this.ref} />;
  }
}
