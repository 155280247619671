export type PopulationEntry = [number, number];

export type BiomassData = {
  [key: string]: number;
};

export interface Biomass {
  total: number;
  location: BiomassData;
  summary: BiomassData;
  animals: BiomassData;
}

export interface LifeData {
  population: PopulationEntry[];
  biomass: Biomass;
}

export default class ApiService {
  async fetchLifeData(): Promise<LifeData> {
    const response = await fetch("/data.json");
    return await response.json();
  }

  async fetchHumansInSpace(): Promise<number> {
    const response = await fetch("http://api.open-notify.org/astros.json");
    const data = await response.json();
    return data.number;
  }
}
