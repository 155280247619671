import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import LifePage from "./LifePage";
import ResponsiveDrawer from "./ResponsiveDrawer";
import { store } from "./PopulationStore";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

store.refresh();

const theme = createMuiTheme({
  palette: {
    type: "dark"
  },
  typography: {
    fontFamily: "'IBM Plex Mono', monospace"
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ResponsiveDrawer>
      <LifePage />
    </ResponsiveDrawer>
  </ThemeProvider>,
  document.getElementById("root")
);
