import React, { Component } from "react";
import { store } from "./PopulationStore";
import { interval } from "rxjs";
import { withLatestFrom, map } from "rxjs/operators";
import moment from "moment";

export default class PopulationCount extends Component<{}, { count: number }> {
  async componentDidMount() {
    const timer = interval(50).pipe(map(() => moment()));

    timer
      .pipe(withLatestFrom(store.population))
      .subscribe(([now, population]) => {
        const currentValue = population.interpolate(now);
        this.setState({ count: Math.round(currentValue) });
      });
  }

  render() {
    return (
      <p>
        Humans on Earth: 
        {this.state?.count.toLocaleString() ?? "0,000,000,000"}
      </p>
    );
  }
}
