import { Subject } from "rxjs";
import ApiService, { Biomass } from "./ApiService";
import TimeSeries, { TimeSeriesEntry } from "./TimeSeries";
import moment from "moment";

export class PopulationStore {
  constructor(
    readonly population = new Subject<TimeSeries>(),
    readonly humansInSpace = new Subject<number>(),
    readonly biomass = new Subject<Biomass>(),
    private service = new ApiService()
  ) {}

  async refresh() {
    this.service.fetchLifeData().then(data => {
      const population = data.population.map<TimeSeriesEntry>(([year, val]) => [
        moment({ year }),
        val
      ]);
      this.population.next(new TimeSeries(population));

      this.biomass.next(data.biomass);
    });

    this.service.fetchHumansInSpace().then(num => this.humansInSpace.next(num));
  }
}

export const store = new PopulationStore();
