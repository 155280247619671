import React, { Component } from "react";
import * as d3 from "d3";

export default class Globe extends Component<{}, {}> {
  ref = React.createRef<HTMLCanvasElement>();
  readonly size = 400;
  interval?: NodeJS.Timeout = undefined;
  update?: () => void = undefined;
  geojson?: any;

  constructor(props: {}) {
    super(props);
    d3.json("/ne_110m_land.geojson").then(geojson => (this.geojson = geojson));
  }

  async componentDidMount() {
    const context = this.ref.current!.getContext("2d")!;

    const projection = d3
      .geoOrthographic()
      .scale(this.size / 2)
      .translate([this.size / 2, this.size / 2]);

    const geoGenerator = d3
      .geoPath()
      .projection(projection)
      .pointRadius(4)
      .context(context);

    const graticule = d3.geoGraticule();

    let yaw = 300;

    this.update = () => {
      yaw -= 0.2;
      projection.rotate([yaw, -30]);

      context.clearRect(0, 0, this.size, this.size);

      context.beginPath();
      geoGenerator({ type: "Sphere" });
      context.fillStyle = "#3f51b5";
      context.fill();

      if (this.geojson) {
        context.fillStyle = "#4caf50";
        context.beginPath();
        geoGenerator(this.geojson);
        context.fill();
      }

      context.beginPath();
      context.strokeStyle = "#303030";
      geoGenerator(graticule());
      context.stroke();
    };

    this.toggleAnimation();
  }

  toggleAnimation = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    } else {
      this.update!();
      this.interval = setInterval(this.update!, 100);
    }
  };

  render() {
    return (
      <canvas
        onClick={this.toggleAnimation}
        className="globe"
        ref={this.ref}
        width="400"
        height="400"
      />
    );
  }
}
