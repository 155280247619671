import React, { Component } from "react";
import { store } from "./PopulationStore";

export default class PopulationCount extends Component<{}, { count: number }> {
  componentDidMount() {
    store.humansInSpace.subscribe(count => this.setState({ count }));
  }

  render() {
    return <p>Humans in Space: {this.state?.count ?? "-"}</p>;
  }
}
